import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  status: boolean = false;
  // statusCart: boolean = false;
  // public all_item:any=[]
  
  constructor() { }
  clickEvent(){
    this.status = !this.status;
  }

  ngOnInit() {
  }

}
