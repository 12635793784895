import { Component, Input, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { MetaService } from '@ngx-meta/core';

interface Item{
  imageSrc:string;
  imageAlt:string;
}
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
@Input() galleryData: Item[] =[];
// images = [
//   'image1',
//   'image2',
//   'image3',
//   'image4',
//   'image5'
// ]
// data:Item[] = [
//   {
//     imageSrc:'https://www.w3schools.com/howto/img_snow_wide.jpg',
//     imageAlt: '1'
//   },
//   {
//     imageSrc:'https://www.w3schools.com/howto/img_snow_wide.jpg',
//     imageAlt: '2'
//   },
// ]
  constructor( private router: Router,private readonly meta: MetaService) { 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  this.meta.setTitle('Lotus Feet Foundation - Gallery');
  this.meta.setTag('og:description', 'LET YOUR LIFE BE AN INSPIRATION!! Vlounteers do not nessarily have the time, They have the heart.');
  this.meta.setTag('twitter:description', 'We as an organization have completed various drives for our causes. We have a team of amazing volunteers and the good graces and support of our community, that helps us run really successfull drives in various location without and hassle and pitch perfrect execution. ');

  this.meta.setTag('og:keyword', 'Lotus Feet Foundation ');
  this.meta.setTag('twitter:keyword', 'Lotus Feet Foundation ');
  this.meta.setTag('og:title', 'Lotus Feet Foundation  - Gallery');
  this.meta.setTag('twitter:title', 'Lotus Feet Foundation  - Gallery');
  this.meta.setTag('og:type', 'website');
  this.meta.setTag('og:image', 'https://technobliss-frontend-assets.s3.amazonaws.com/lotusfeetfoundation-frontend/images/LOGO - 1.png');
  this.meta.setTag('twitter:image', 'https://technobliss-frontend-assets.s3.amazonaws.com/lotusfeetfoundation-frontend/images/LOGO - 1.png');
  }

  ngOnInit() {
  }
onPrivewImage(index:number):void{

}
}
