
import { Component, OnInit,HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  windowScrolled: boolean;
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      }
      else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
  }  
  scrollToTop() {
      (function smoothscroll() {  
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;  
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
        })
    ();
  }
  privacy() {
    const dialogRef = this.dialog.open(PrivacyPolicy,{
      panelClass:'privacyModal'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });

  }
  termsandConditions() {
    const dialogRef = this.dialog.open(TermsAndConditions, {
      panelClass:'termsconditionModal'
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log(`Dialog result: ${result}`);
    });
  }
}
@Component({
  selector: 'app-privacypolicy',
  templateUrl: 'privacypolicy.html',
  styleUrls: ['./footer.component.css']
})
export class PrivacyPolicy { }

@Component({
  selector: 'app-termsandconditions',
  templateUrl: 'termsandconditions.html',
  styleUrls: ['./footer.component.css']
})
export class TermsAndConditions { }

