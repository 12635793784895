import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HomeComponent } from './components/home/home.component';
import { MembershipComponent } from './components/membership/membership.component';
import { SupportComponent } from './components/support/support.component';
import { NgoSchoolComponent } from './components/ngo-school/ngo-school.component';


const routes: Routes = [
  // {path:'', component: NgoSchoolComponent}, 
  // {path: '**', redirectTo: '', pathMatch: 'full'},
  // ========================================
  {path:'', component: HomeComponent}, 
  {path:'home', component: HomeComponent}, 
  {path:'about-us', component: AboutUsComponent}, 
  {path:'membership', component: MembershipComponent}, 
  {path:'gallery', component: GalleryComponent}, 
  {path:'support', component: SupportComponent}, 
  {path:'contact-us', component: ContactUsComponent}, 
  {path:'education', component: NgoSchoolComponent}, 
  {path: '**', redirectTo: 'home', pathMatch: 'full'},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
