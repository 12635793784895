import { ElementRef, EventEmitter, Injectable, Input, ViewChild } from '@angular/core';
import { switchMap, map, takeWhile, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// for setting observables to get serverurl and endpointurl from app
import { Observable, Subject, Subscription, throwError } from 'rxjs';
// import{CookieService} from 'ngx-cookie-service';
import {environment } from '../../environments/environment';
// import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  

  public serverUrlDemo1 =  environment["API_URL1"];

  public serverUrlDemo =  environment["API_URL"];

  public nodesslurl =  environment["nodesslurl"];

  public uploadurl =  environment["uploadurl"];
  public base64encode =  environment["base64encode"];
  public uploadsslurl: any= environment["download_url"];
  public inventory_url: string ;
  // = environment["inventory_url"] + environment["inventory_url_api"];
  public inventory_auto_complete_url: string;
  //  = environment["inventory__auto_completeurl"] + environment["inventory_url_api"];
  public share_link : string = environment["share_link"];
  public Meta_image_url : string = environment["Meta_image_url"];

  public fileimgsslurl: any;
  private _snackBar: MatSnackBar


  fileservername: any = [];
  serverUrl: any;
  addendpointUrl: any;
  uploadEndpointUrl:any; //souresh
  updateendpointUrl: any;
  deletesingle_endpointUrl: any;
  updatestatus_single_endpointUrl: any;
  deletemultiple_endpointUrl: any;
  updatestatus_multiple_endpointUrl: any;
  getdata_endpointUrl: any;
  public invalidApi:any;
  
  public tokenVal: any;

  constructor(private _http: HttpClient) {}

  //http by data and endpoint
  postDatawithoutToken(endpoint:any, data:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log('data+++++++', endpoint, data)
    var result = this._http.post(this.serverUrlDemo+endpoint, JSON.stringify(data), httpOptions).pipe(map(res => res));
    return result;
  }

  openSnackBar() {
    this._snackBar.open('Something Went Wrong ,Try Again!!', 'ok', {
      duration: 6000,
    });
  }

  
  

}
