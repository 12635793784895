import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetaModule } from '@ngx-meta/core';
// import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent, PrivacyPolicy, TermsAndConditions } from './layouts/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { DemoMaterialModule } from './modules/material-module';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SupportComponent } from './components/support/support.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { MembershipComponent } from './components/membership/membership.component';
import { HttpLoaderComponent } from './common/http-loader/http-loader.component';
import { LoaderService } from './modules/loader.service';
import { LoaderInterceptor } from '../app/common/loader.interceptor';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgoSchoolComponent } from './components/ngo-school/ngo-school.component';
// import { LazyLoadModule } from 'ng-lazy-load';
// import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    GalleryComponent,
    SupportComponent,
    ContactUsComponent,
    AboutUsComponent,
    MembershipComponent,
    HttpLoaderComponent,
    PrivacyPolicy,
    TermsAndConditions,
    NgoSchoolComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // RouterModule.forRoot(routes),
    MetaModule.forRoot(),
    // MetaModule.forRoot({
    //   provide: MetaLoader,
    // }),
    AppRoutingModule,
    DemoMaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    // LazyLoadImageModule
    // MDBBootstrapModule.forRoot()

  ],
  providers: [LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent ],
  entryComponents:[PrivacyPolicy,TermsAndConditions]

})
export class AppModule { }
