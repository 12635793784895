import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { MatDialog,MatSnackBar } from '@angular/material';
import {HttpService} from '../../modules/http.service'

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  options: FormGroup;
  public successModal: any = false;
  public isLoading = false;
  constructor(public router: Router, public fb: FormBuilder, public dialog: MatDialog,private readonly meta: MetaService,public apiService:HttpService,public _snackBar: MatSnackBar ) {
    this.options = this.fb.group({
      name:['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/)])],
      phone:['',Validators.required],
      message:['',Validators.required],
      type:['contactus']
    })
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  this.meta.setTitle('Lotus Feet Foundation - Contact us');
  this.meta.setTag('og:description', 'LET YOUR LIFE BE AN INSPIRATION!! Vlounteers do not nessarily have the time, They have the heart.');
  this.meta.setTag('twitter:description', 'We as an organization have completed various drives for our causes. We have a team of amazing volunteers and the good graces and support of our community, that helps us run really successfull drives in various location without and hassle and pitch perfrect execution. ');

  this.meta.setTag('og:keyword', 'Lotus Feet Foundation ');
  this.meta.setTag('twitter:keyword', 'Lotus Feet Foundation ');
  this.meta.setTag('og:title', 'Lotus Feet Foundation  - Contact us');
  this.meta.setTag('twitter:title', 'Lotus Feet Foundation  - Contact us');
  this.meta.setTag('og:type', 'website');
  this.meta.setTag('og:image', 'https://technobliss-frontend-assets.s3.amazonaws.com/lotusfeetfoundation-frontend/images/LOGO - 1.png');
  this.meta.setTag('twitter:image', 'https://technobliss-frontend-assets.s3.amazonaws.com/lotusfeetfoundation-frontend/images/LOGO - 1.png');
  }

  ngOnInit() { }
  contactUs(){
    console.log(this.options.value);
    let x: any;
    // use for validation checking
    for (x in this.options.controls) {
      this.options.controls[x].markAsTouched();
    }

    if (this.options.valid) {
      let data: any = this.options.value;
      let link = "/lotusfeetContactSubmission";
      this.isLoading=true
      this.apiService.postDatawithoutToken(link, data).subscribe((response:any)=>{
          console.log("response",response['msg']);  
          this.isLoading=false
            this._snackBar.open(response['msg'], 'Ok', {
              duration: 1500,
            });
            this.options.reset();
      });
    }
  }
}
