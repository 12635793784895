import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { MatDialog,MatSnackBar } from '@angular/material';
import {HttpService} from '../../modules/http.service'

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-ngo-school',
  templateUrl: './ngo-school.component.html',
  styleUrls: ['./ngo-school.component.css']
})
export class NgoSchoolComponent implements OnInit {
  options: FormGroup;
  public successModal: any = false;
  public isLoading = false;
  constructor(public router: Router, public fb: FormBuilder, public dialog: MatDialog,private readonly meta: MetaService,public apiService:HttpService,public _snackBar: MatSnackBar ) {
    this.options = this.fb.group({
      name:['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/)])],
      phone:['',Validators.required],
      message:['',Validators.required],
      type:['registration']
    })
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  this.meta.setTitle('Lotus Feet Foundation - Education');
  this.meta.setTag('og:description', 'LET YOUR LIFE BE AN INSPIRATION!! Get a chance higher education in aborad');
  this.meta.setTag('twitter:description', "Lotus Feet Foundation are being offered Bachelor's, master's and Phd Degree by various Non-Government Organisations in our country and also in aborad.");

  this.meta.setTag('og:keyword', 'Lotus Feet Foundation ');
  this.meta.setTag('twitter:keyword', 'Lotus Feet Foundation ');
  this.meta.setTag('og:title', 'Lotus Feet Foundation  - Education');
  this.meta.setTag('twitter:title', 'Lotus Feet Foundation  - Education');
  this.meta.setTag('og:type', 'website');
  this.meta.setTag('og:image', 'https://technobliss-frontend-assets.s3.amazonaws.com/lotusfeetfoundation-frontend/images/LOGO - 1.png');
  this.meta.setTag('twitter:image', 'https://technobliss-frontend-assets.s3.amazonaws.com/lotusfeetfoundation-frontend/images/LOGO - 1.png');
  }

  ngOnInit() {
  }
  registerWithUs(){
    console.log(this.options.value);
    let x: any;
    // use for validation checking
    for (x in this.options.controls) {
      this.options.controls[x].markAsTouched();
    }

    if (this.options.valid) {
      let data: any = this.options.value;
      let link = "/lotusfeetContactSubmission";
      this.isLoading=true
      this.apiService.postDatawithoutToken(link, data).subscribe((response:any)=>{
          console.log("response",response['msg']);  
          this.isLoading=false
            this._snackBar.open(response['msg'], 'Ok', {
              duration: 1500,
            });
            this.options.reset();
      });
    }
  }
}
